import { z } from 'zod';

export const ProfilePreferencesSchema = z.object({
  isPublic: z.boolean(),
  isIndexable: z.boolean(),
  slug: z.string(),
  defaultView: z.enum(['classic', 'timeline']),
  isTimelineShowEmail: z.boolean(),
  isTimelineShowTelephone: z.boolean(),
  isClassicShowEmail: z.boolean(),
  isClassicShowTelephone: z.boolean()
});

export type ProfilePreferences = z.infer<typeof ProfilePreferencesSchema>;
