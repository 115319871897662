import Button from 'components/shared/Button.styled';
import { useI18n } from 'hooks/useI18n';
import { withTranslation } from 'next-i18next';
import { StyledContainer } from './OfferApplyModal.styled';
import { StyledActions } from './OfferApplyModalAplicationComplete.styled';
import OfferApplyModalPositionCard from './OfferApplyModalPositionCard';
import { OfferApplyModalPositionOpenMissingDataProps } from './OfferApplyModalPositionOpenMissingData.types';

const OfferApplyModalPositionOpenMissingData: React.FC<
  OfferApplyModalPositionOpenMissingDataProps
> = ({
  uiOffer,
  applyModalClose,
  handleNoClick,
  alreadyShowMissingDataModal,
  setAlreadyShowMissingDataModal,
  setAlreadyShowInSessionMissingDataModal,
  alreadyShowInSessionMissingDataModal
}) => {
  const { t } = useI18n('apply-modal');

  return (
    <>
      <StyledContainer>
        {!alreadyShowInSessionMissingDataModal && (
          <>
            <p>
              {!alreadyShowMissingDataModal
                ? t('missing-data.subtitle')
                : t('missing-data-round2.subtitle')}
            </p>

            <OfferApplyModalPositionCard uiOffer={uiOffer} />

            <StyledActions>
              <Button
                variant="primary"
                id="modal-go-button"
                aria-label={
                  !alreadyShowMissingDataModal
                    ? t('missing-data.CTA')
                    : t('missing-data-round2.CTA-complete')
                }
                onClick={() => {
                  setAlreadyShowMissingDataModal(true);
                  applyModalClose();

                  setAlreadyShowInSessionMissingDataModal(true);
                }}
              >
                <span aria-hidden="true">
                  {!alreadyShowMissingDataModal
                    ? t('missing-data.CTA')
                    : t('missing-data-round2.CTA-complete')}
                </span>
              </Button>

              {alreadyShowMissingDataModal && (
                <Button
                  variant="secondary"
                  id="modal-not-go-button"
                  aria-label={t('missing-data-round2.CTA-stop-application')}
                  onClick={() => {
                    setAlreadyShowMissingDataModal(true);
                    setAlreadyShowInSessionMissingDataModal(true);
                    applyModalClose();
                    handleNoClick();
                  }}
                >
                  <span aria-hidden="true">
                    {t('missing-data-round2.CTA-stop-application')}
                  </span>
                </Button>
              )}
            </StyledActions>
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default withTranslation(['apply-modal'])(
  OfferApplyModalPositionOpenMissingData
);
