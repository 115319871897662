import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useReducer } from 'react';
import { ManfrediteRulesService } from 'services/manfredite-rules.service';
import { getLocale } from 'utils/localize.utils';
import { ManfrediteFieldsStatusContext } from './manfredite-fields-status.context';
import { INITIAL_STATE } from './manfredite-fields-status.provider.constants';
import ManfrediteFieldsStatusReducer from './manfredite-fields-status.provider.reducer';
import { ManfrediteFieldsStatusReducerActionType } from './manfredite-fields-status.provider.types';

export const ManfrediteFieldsStatusProvider: React.FC = ({ children }) => {
  const { locale } = useRouter();

  const { user } = useContext(LoggedUserContext);

  const [state, dispatch] = useReducer(
    ManfrediteFieldsStatusReducer,
    INITIAL_STATE
  );

  const { isLoading, isManfrediteSaved, fieldsStatus } = state;

  const fulfilledFieldsForOfferApplying =
    fieldsStatus?.fulfilledFieldsForOfferApplying || [];

  const pendingFieldsForOfferApplying =
    fieldsStatus?.pendingFieldsForOfferApplying;

  const fetchData = useCallback(async () => {
    if (!user) {
      return;
    }

    const fieldsStatus = await ManfrediteRulesService.offerApply.get(
      getLocale(locale)
    );

    dispatch({
      type: ManfrediteFieldsStatusReducerActionType.SET_FIELDS_STATUS,
      payload: {
        fieldsStatus
      }
    });
  }, [locale, user]);

  const markManfrediteAsSaved = useCallback(() => {
    dispatch({
      type: ManfrediteFieldsStatusReducerActionType.SET_MANFREDITE_AS_SAVED,
      payload: null
    });

    // ? Should we call the API to get updated FieldStatus everytime we save the Manfredite? Is it needed? 🤔'
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <ManfrediteFieldsStatusContext.Provider
      value={{
        isManfrediteSaved,
        isLoading,
        fieldsStatus: {
          fulfilledFieldsForOfferApplying,
          pendingFieldsForOfferApplying
        },
        userCouldApplyToOffers: state.userCouldApply,
        userCanApplyToOffers:
          !!pendingFieldsForOfferApplying &&
          pendingFieldsForOfferApplying.length <= 0 &&
          isManfrediteSaved,
        markManfrediteAsSaved,
        recalculateFieldsForOfferApplying: fetchData
      }}
    >
      {children}
    </ManfrediteFieldsStatusContext.Provider>
  );
};
