/* eslint-disable no-undef */
import { rgba } from 'polished';
import { createGlobalStyle } from 'styled-components';
import {
  color,
  fontFamily,
  fontSize,
  fontWeight,
  radius, screen, shadow, spacing,
  zindex
} from 'theme';
import { getPublicPath } from 'utils/paths';

function getFont(fontName) {
  return `local(''),
  url('${getPublicPath('/fonts/'+ fontName +'.woff2')}') format('woff2'),
  url('${getPublicPath('/fonts/'+ fontName +'.woff')}') format('woff')`
}

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ShareTechMono';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: ${() => getFont('Share-Tech-Mono')}
  }
  @font-face {
    font-family: 'OpenBrush';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: ${() => getFont('OpenBrush')}
  }

  @font-face {
    font-family: 'MTG';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: ${() => getFont('MTG')}
  }

  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: ${() => getFont('Avenir-Roman')}
  }

  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
    src: ${() => getFont('Avenir-Medium')}
  }

  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-display: fallback;
    src: ${() => getFont('Avenir-Heavy')}
  }

  @font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-display: fallback;
    src: ${() => getFont('Avenir-Black')}
  }

  *{
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  html.normal-scroll {
    scroll-behavior: auto;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    background-color: ${color('white')};
    font-family: ${fontFamily('sans')};
    font-weight: ${fontWeight('regular')};
    font-size: ${fontSize('md')};
    color: ${color('text_default')};
    -webkit-font-smoothing: antialiased;
    -webkit-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
  }

  hr {
    height: 1px;
    background: #eee;
    width: 100%;
    border: 1px solid #eee;
  }

  @media screen and (max-width: ${screen('mdMax')}) {
    body .react-toast-notifications__container {
      left: 0;
      right: 0;
      transform: none;
    }
  }

  @media screen and (max-width: ${screen('mdMax')}) {
    #freshworks-container {
      display: none !important;
    }
  }

  @media print {
    #freshworks-container {
      display: none !important;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ol + p,
  ul + p {
    margin-top: ${spacing('xl')};
  }

  li {
    list-style: none;
  }

  // We need to setup Quill's nested lists styles
  li.ql-indent-1 {
    margin-left: ${spacing('lg')};
  }

  li.ql-indent-2 {
    margin-left: ${spacing('xl3')};
  }

  a {
    color: ${color('link')};
    cursor: pointer;
    text-decoration: none;
    transition: .3s;

    &:hover {
      color: ${color('link_hover')};
    }

    &:focus,
    &:active {
      outline: none;
      color: ${color('link_active')};
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .sr-only {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important; 
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;  
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }

  .mobile-scroll-blocked {
    @media (max-width: ${screen('mdMax')}) {
      overflow: hidden;
    }
  }

  /* Slick slider */

  .slick-slider {
    padding-bottom: calc(${spacing('lg')} + ${spacing('md')});
  }

  .slick-dots {
    bottom: 0;
    display: flex !important;
    justify-content: center;
    margin-left: 0 !important;
    margin-top: ${spacing('sm')};
    

    li {
      position: static;
      width: ${spacing('md')};
      height: ${spacing('md')};
      margin: 0 ${spacing('xs')} !important;
      list-style-type: none !important;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }

      button {
        width: ${spacing('md')};
        height: ${spacing('md')};
        padding: 0;
        font-size: 0;
        line-height: 0;
        background-color: ${color('white')};
        border: 2px solid ${color('midnight_200')};
        border-radius: ${radius('full')};
        transition: .3s;

        &::before {
          display: none;
        }

        &:hover {
          border-color: ${color('midnight_800')};
        }
      }

      &.slick-active button {
        border-color: ${color('active')};
        background-color: ${color('active')};
      }
    }
  }

  /* Tooltip */

  .react-tooltip-lite {
    box-sizing: border-box;
    background: ${color('midnight_800')};
    color: ${color('text_default_inverse')};
    border-radius: ${radius('sm')};
    font-size: ${fontSize('sm')};
    line-height: 1.33;
    font-weight: ${fontWeight('medium')};
    text-align: center;

    strong {
      font-weight: ${fontWeight('black')};
    }

    p{
      margin: 0;

      &:not(:first-child) {
        margin-top: 1em;
      }
    }

    a {
      color: ${color('link_inverse')};

      &:hover {
        color: ${color('link_inverse_hover')};
      }

      &:active {
        color: ${color('link_inverse_active')};
      }
    }

    .tooltip__highlight {
      font-weight: ${fontWeight('black')};
      color: ${color('active')};
    }

    .tooltip__title {
      font-family: ${fontFamily('serif')};
      font-weight: ${fontWeight('bold')};
      font-size: ${fontSize('xl')};
      line-height: 1.17;
      margin-top: 0;
      margin-bottom: ${spacing('xs')};
      padding-bottom: ${spacing('xs')};
      position: relative;

      &::before {
        content: "";
        display: block;
        height: ${spacing('xs2')};
        width: ${spacing('xl4')};
        background-color: ${color('midnight_700')};
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .react-tooltip-lite-arrow {
    border-color: ${color('midnight_800')};
    /* fixes a rendering bug in firefox */
    transform: scale(1.2);
  }

  .tooltip--md {
    max-width: 14rem;
  }

  .tooltip--lg {
    max-width: 26rem;
  }

  .type--full-content {
    text-align: left;

    ul, ol {
      margin-top: ${spacing('sm')};
    }

    ul li {
        list-style-type: disc;
        margin-left: ${spacing('md')};
      }
  }

  /* Headroom */

  .headroom {
    z-index: 2 !important;
  }

  .sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
  }

  /* React-modal */

  .ReactModal__Overlay {
    background-color: ${rgba('#353241', 0.8)} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${zindex('modal')};
    transition: opacity .3s ease-in-out;
    opacity: 0;

    &--after-open {
      opacity: 1;
    }
  }

  .ReactModal__Content {
    position: static !important;
    border: 0 !important;
    background: transparent !important;
    border-radius: 0 !important;
    padding: 0 !important;
    overflow: visible !important;
    transition: all .3s ease-in-out;
    transform: translateY(30px);
    opacity: 0;

    &--after-open {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mf-creatable-select {
    &__control {
      border-radius: ${radius('md')} !important;
      border: 2px solid ${color('midnight_200')} !important;
      min-height: 3.5rem !important;
      font-size: ${fontSize('sm')};
      font-weight: ${fontWeight('medium')};
      transition: .3s !important;
      color: ${color('text_default')} !important;

      &:hover {
        border-color: ${color('midnight_400')} !important;

        .cs-transparent & {
          border-color: ${color('midnight_150')} !important;
          background-color: ${color('midnight_150')};
        }
      }

      &--is-focused {
        outline: 0;
        border-color: ${color('midnight_850')} !important;
        box-shadow: 0 0 0 4px ${color('midnight_150')} !important;
      }

      .mf-creatable-select__placeholder {
        color: ${color("midnight_300")};
      }

      .mf-creatable-select__value-container {
        padding-left: 1.25rem;
        padding-right: 0;

      }
    }

    &__indicator-separator {
      display: none !important;
    }

    &__dropdown-indicator {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.538289 0.730988H7.46171C7.94067 0.730988 8.18015 1.30951 7.84111 1.64855L4.38075 5.11161C4.17087 5.32149 3.82913 5.32149 3.61925 5.11161L0.158886 1.64855C-0.180154 1.30951 0.0593268 0.730988 0.538289 0.730988Z' fill='%23353241'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.65em auto;
      padding: ${spacing('lg')} ${spacing('lg')} ${spacing('lg')} ${spacing('xs')} !important;
      
      .cs-sm & {
        padding: ${spacing('sm')} ${spacing('lg')} ${spacing('sm')} ${spacing('xs')} !important;
      }

      svg {
        display: none;
      }

      .mf-select__control--menu-is-open &{
        transform: rotate(180deg);
      }
    }

    &__clear-indicator {
      color: ${color('midnight_800')} !important;
      padding: ${spacing('xs2')} ${spacing('xs')} !important;
      margin-right: 0;
      border-right: 1px solid ${color('midnight_200')};
      
      svg {
        width: ${spacing('md')} !important;
        height: ${spacing('md')};
      }
    }

    &__menu {
      border-radius: ${radius('md')} !important;
      border: 2px solid ${color('midnight_200')} !important;
      margin-top: ${spacing('xs2')} !important;
      box-shadow: ${shadow('lg')} !important;
      overflow: hidden;
    }

    &__menu-list {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &__option {
      font-size: ${fontSize('sm')} !important;
      font-weight: ${fontWeight('medium')};
      padding: 20px !important;

      &--is-focused {
        background-color: ${color('midnight_150')} !important;
      }

      &--is-selected {
        background-color: ${color('active')} !important;
        color: ${color('text_over_active')} !important;
        font-weight: ${fontWeight('black')};
      }
    }
  }

  /* React-select */

  .mf-select {
    &__menu {
      .cs-limit-reached {
        padding: ${spacing('md')};
      }
    }
    
    &__control {
      border-radius: ${radius('md')} !important;
      border: 2px solid ${color('midnight_200')} !important;
      min-height: 3.5rem !important;
      font-size: ${fontSize('sm')};
      font-weight: ${fontWeight('medium')};
      transition: .3s !important;
      color: ${color('text_default')} !important;

      .cs-sm & {
        min-height: 2.5rem !important;
        font-size: ${fontSize('xs')};
      }

      .cs-transparent & {
        border-color: transparent !important;
        text-transform: uppercase;
        font-weight: ${fontWeight('black')};
      }

      .mf-select.cs-error & {
        border-color: ${color('negative_500')} !important;
      }

      &:hover {
        border-color: ${color('midnight_400')} !important;

        .cs-transparent & {
          border-color: ${color('midnight_150')} !important;
          background-color: ${color('midnight_150')};
        }

        .cs-error & {
          border-color: ${color('negative_500')} !important;
        }
      }

      &--is-focused {
        outline: 0;
        border-color: ${color('midnight_850')} !important;
        box-shadow: 0 0 0 4px ${color('midnight_150')} !important;
      }

      .mf-select__placeholder {
        color: ${color("midnight_300")};
      }

      .mf-select__value-container {
        padding-left: 1.25rem;
        padding-right: 0;

        .cs-sm & {
          padding-left: ${spacing('sm')};
        }
      }
    }

    &__indicator-separator {
      display: none !important;
    }

    &__dropdown-indicator {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.538289 0.730988H7.46171C7.94067 0.730988 8.18015 1.30951 7.84111 1.64855L4.38075 5.11161C4.17087 5.32149 3.82913 5.32149 3.61925 5.11161L0.158886 1.64855C-0.180154 1.30951 0.0593268 0.730988 0.538289 0.730988Z' fill='%23353241'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.65em auto;
      padding: ${spacing('lg')} ${spacing('lg')} ${spacing('lg')} ${spacing('xs')} !important;
      
      .cs-sm & {
        padding: ${spacing('sm')} ${spacing('lg')} ${spacing('sm')} ${spacing('xs')} !important;
      }

      svg {
        display: none;
      }

      .mf-select__control--menu-is-open &{
        transform: rotate(180deg);
      }
    }

    &__clear-indicator {
      color: ${color('midnight_800')} !important;
      padding: ${spacing('xs2')} ${spacing('xs')} !important;
      margin-right: 0;
      border-right: 1px solid ${color('midnight_200')};
      
      svg {
        width: ${spacing('md')} !important;
        height: ${spacing('md')};
      }
    }

    &.mf-select-autocomplete &__clear-indicator {
      border-right: 0;
    }

    &__menu {
      border-radius: ${radius('md')} !important;
      border: 2px solid ${color('midnight_200')} !important;
      margin-top: ${spacing('xs2')} !important;
      box-shadow: ${shadow('lg')} !important;
      overflow: hidden;
    }

    &__menu-list {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &__option {
      font-size: ${fontSize('sm')} !important;
      font-weight: ${fontWeight('medium')};
      padding: 20px !important;

      &--is-focused {
        background-color: ${color('midnight_150')} !important;
      }

      &--is-selected {
        background-color: ${color('active')} !important;
        color: ${color('text_over_active')} !important;
        font-weight: ${fontWeight('black')};
      }
    }

    &__single-value,
    &__multi-value {
      margin-left: 0;
      margin-right: 0;
    }

    .multi-value-separator {
      display: inline-block;
      margin: 0 ${spacing('xs2')};
    }
    
    &__multi-value {
      background-color: ${color('white')} !important;
      border: 2px solid ${color('midnight_200')};
      border-radius: ${radius('full')} !important;

      &__label {
        padding: ${spacing('xs')} ${spacing('xs')} ${spacing('xs')} ${spacing('md')} !important;
        font-size: ${fontSize('xs')} !important;
        font-weight: ${fontWeight('medium')};
        line-height: 1;

        .cs-sm & { 
          padding: ${spacing('xs2')} ${spacing('xs')} ${spacing('xs2')} ${spacing('xs')} !important;

        }
      }

      &__remove {
        padding-right: ${spacing('xs')} !important;
        color: ${color('black')} !important;

        &:hover {
          background: transparent !important;
          color: ${color('midnight_800')} !important;
        }
      }
    }
  }

  /* Cookiebot Styles */
  #CybotCookiebotDialogPoweredbyCybot {
    display: none !important;
  }

  a#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
    padding-left: 15px;
  }

  #CybotCookiebotDialog {

    @media screen and (max-width: 1280px) {
      bottom: 0;
      top: auto !important;
      transform: translate(-50%,0%) !important;
    }
    
    * {
      font-family: Avenir, sans-serif !important;
    }

    .CybotCookiebotDialogContentWrapper {
      align-items: center !important;
    }

    &.CybotEdge #CybotCookiebotDialogHeader {
      display: none !important;
    }

    .CybotCookiebotDialogBodyButton {
      text-transform: uppercase;
      font-size: .875rem !important;
      font-weight: 700 !important;
      outline: none !important;

      &:focus {
        outline: none !important;  
      }
    }
  }
  /* END Cookiebot Styles */

  .wp-block-cover {
    text-align: center;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
      border: 0 !important;
      padding: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      margin: 0 auto  !important;
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      height: 100%;
    }

    > .wp-block-cover__inner-container {
      z-index: 1;
      position: relative;

      > h4 {
        font-size: 2.25rem;
        font-weight: 900;
        font-family: Avenir, sans-serif;
        margin-top: 0;
      }

      > p {
        font-size: 1rem;
        max-width: 70%;
        margin: 0 auto 1rem auto;
      }

      .wp-block-button {
        margin-top: 0;

        .wp-block-button__link {
          background: #fff !important;
          color: #333 !important;
          border: 2px solid #ccc;

          &:hover {
            background: #333 !important;
            color: #fff !important;
          }
        }
      }
    }
  }
`;

export default GlobalStyles;
