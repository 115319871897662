import * as fetch from 'utils/fetch';

const BASE_URL = `${process.env.basePath}/api/v2/manfredite/offer-apply`;

/**
 * Store an offer application attempt for the current logged user
 *
 * @param offerId - the id of the offer to store the offer application attempt
 */
async function storeApplicationAttempt(offerId: number): Promise<void> {
  return fetch.post<void, void>(
    `${BASE_URL}/offer/${offerId}/attempt`,
    undefined
  );
}

/**
 * Dismiss an offer application attempt for the current logged user
 *
 * @param offerId - the id of the offer to dismiss the offer application attempt
 */
async function dismissApplicationAttempt(offerId: number): Promise<void> {
  return fetch._delete<void>(`${BASE_URL}/offer/${offerId}/attempt`);
}

/**
 * Apply to an offer in behalf of the current logged user
 *
 * @param offerId - the id of the offer the user wants to apply
 */
async function applyToOffer(offerId: number): Promise<void> {
  return fetch.post<void, void>(
    `${BASE_URL}/offer/${offerId}/apply`,
    undefined
  );
}

const OfferApplicationService = {
  storeApplicationAttempt,
  dismissApplicationAttempt,
  applyToOffer
};

export default OfferApplicationService;
