import { FetchError } from 'model';
import CookiesService, {
  COOKIES_NEXT_LOCALE
} from 'services/ui/cookies.service';
import { APP_URLS, getAppUrl } from './app-urls';

function pathShouldReturnDataOnError(path: string): boolean {
  const validPathSegments = ['/public/manfredite/job-application/'];

  return validPathSegments.some((segment) => path.includes(segment));
}

async function handleError(response: Response): Promise<unknown> {
  const body = await response
    .json()
    .catch(() => ({ message: response.statusText }));

  const err = new FetchError({
    statusCode: response.status,
    statusText: response.statusText
  });

  err.message = body.message;

  throw err;
}

async function http<T>(
  path: string,
  config: RequestInit,
  handle401 = true
): Promise<T> {
  const response = await fetch(path, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'x-anonymous-id': global?.analytics?.user
        ? global.analytics.user().anonymousId() || ''
        : '',
      ...config.headers
    },
    ...config
  });

  if (!response.ok) {
    if (handle401 && response.status === 401 && typeof window !== 'undefined') {
      const locale = CookiesService.get(COOKIES_NEXT_LOCALE) || 'en';

      window.location.replace(
        `${window.location.origin}/${locale}${getAppUrl(
          APP_URLS.logout,
          locale
        )}`
      );
    } else if (response.status === 403 && pathShouldReturnDataOnError(path)) {
      return response.json().catch(() => ({ message: response.statusText }));
    }

    await handleError(response);
  }

  return response.json().catch(() => ({}));
}

export async function get<T>(
  path: string,
  config?: RequestInit | undefined,
  handle401?: boolean
): Promise<T> {
  return await http<T>(path, { method: 'get', ...config }, handle401);
}

export async function post<T, U>(
  path: string,
  body: T,
  config?: RequestInit | undefined
): Promise<U> {
  return await http<U>(path, {
    method: 'POST',
    body: body instanceof FormData ? body : JSON.stringify(body),
    ...config
  });
}

export async function put<T, U>(
  path: string,
  body: T,
  config?: RequestInit | undefined
): Promise<U> {
  return await http<U>(path, {
    method: 'put',
    body: JSON.stringify(body),
    ...config
  });
}

export async function patch<T, U>(
  path: string,
  body: T,
  config?: RequestInit | undefined
): Promise<U> {
  return await http<U>(path, {
    method: 'PATCH',
    body: JSON.stringify(body),
    ...config
  });
}

export async function _deleteWithBody<T, U>(
  path: string,
  body: T,
  config?: RequestInit | undefined
): Promise<U> {
  return await http<U>(path, {
    method: 'delete',
    body: JSON.stringify(body),
    ...config
  });
}

export async function _delete<T>(
  path: string,
  config?: RequestInit
): Promise<T> {
  return await http<T>(path, { method: 'delete', ...config });
}
